<template>
    <div>
        <div class="row justify-content-center">
            <div class="col">
                <h1 class="mt-100 text-center">Terms and Conditions</h1>
            </div>
        </div>
        <div class="container mb-20">
            <div class="row">
                <div class="col-md-12">
                    <ol>
                        <li>I acknowledge receipt of my ASOLAR System with serial number attahced to my device.</li>
                        <li>
                            I understand that:
                            <ul>
                                <li>I am responsible for the payment in outrights payment and instalment for the unit (s).</li>
                                <li>If I obtain the unit on a payment plan, I am responsible for the down payment with a monthly payment for the duration of my contract until the total amount has been paid</li>
                            </ul>
                        </li>
                        <li>
                            I understand that the system is not my property until I have completed all payments and unlocked the system. Until this time:
                            <ul>
                                <li>I must not attempt repairs or alterations to the system and must not open any part of the system.</li>
                                <li>I must not use the system for any purpose other than intended or any use other than instructed, sell or give the system to someone else.</li>
                                <li>I am responsible for the cost of any damage or loss outside of the warranty terms.</li>
                                <li>I understand that if I don't keep up with my payments in terms of a loan purchase, I will risk the lender, ASOLAR Systems Nigeria Limited, taking legal actions in order to recoup the outstanding loan amount which may come with additional legal fees.</li>
                            </ul>
                        </li>
                        <li>I understand that authorised repairs will be carried out by an ASOLAR agent only. If anyone offers to service my system, I must confirm that this person is authorised to do so by contacting Customer Support.</li>
                        <li>I understand that my payment activity is monitored and that I must keep my accounts in credit.</li>
                        <li>I confirm all of my personal information is correct and I agree to inform ASOLAR of any changes to my personal information.</li>
                        <li>
                            I agree that my personal and system usage information will be shared with ASOLAR Systems Nigeria Limited and other selected third parties, and that this data may be held and processed outside of my country of residence, in accordance with the following:
                            <ul>
                                <li>The sharing of data is solely for the purposes of providing and continuously improving service.</li>
                                <li>ASOLAR agrees to protect customer data in accordance with the relevant laws of the countries where data is processed</li>
                            </ul>
                        </li>
                        <li>I understand the warranty conditions, and that the warranty period is for 24 months from the date of installation. I understand that full warranty conditions will be provided along with my agreed purchase.</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TermsAndConditions",
    computed: {},
}
</script>

<style scoped>
.mt-100 {
    margin-top: 100px;
}
.mb-20 {
    margin-bottom: 20px;
}
</style>